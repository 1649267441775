.our-services {
    padding-top: 50px;
    // padding-bottom: 120px;
}

.service-content {
    .content-row {
        margin-bottom: 125px !important;
        // img {
        //     filter: grayscale(1);
        // }
    }
    .content-head h2 {
        font-weight: 700 !important;
    }
    .card {
        background-color: transparent !important;
        border: none !important;
    }
    // .card-body {
    //     padding: 56px 0 0 0;
    // }
    .card-title {
        font-size: 45px;
        font-weight: 700;
        margin-bottom: 29px;
    }
    .card-text {
        font-size: 18px;
        font-weight: 400;
        text-align: justify;
        margin-bottom: 54.93px;
    }
}
.card-img-top {
    width: 100%;
    object-fit: cover !important;
    height: 100% !important;
}

.swservice-row {
    margin-bottom: 34px;
    .image-service {
        position: relative;
        transition: transform 0.5s ease;
        a {
            color: #fff !important;
            text-decoration: none;
            position: absolute;
            top: 75%;
            left: 25%;
        }
        img {
            margin-right: 4px;
            // filter: grayscale(1);
            width: 100%;
            height: auto;
            object-fit: contain;
            border-radius: 6px;
        }
    }
    .image-service:hover {
        transform: scale(1.2);
    }
}

.accordion-content:nth-child(1) {
    width: 150px;
    a {
        font-size: 12px;
        font-weight: 700;
    }
}
.accordion-content:nth-child(2) {
    width: 200px;
    a {
        font-size: 16px;
        font-weight: 700;
    }
}
.accordion-content:nth-child(3) {
    width: 250px;
    a {
        font-size: 20px;
        font-weight: 700;
    }
}
.accordion-content:nth-child(4) {
    width: 200px;
    a {
        font-size: 16px;
        font-weight: 700;
    }
}
.accordion-content:nth-child(5) {
    width: 150px;
    a {
        font-size: 12px;
        font-weight: 700;
    }
}

@media (max-width: 991.99px) {
    .our-services .card-body {
        padding: 14px !important;
    }
    .our-services .service-content .content-row {
        margin-bottom: 45.63px !important;
    }
}
@media (min-width: 992px) {
    .accordion-content {
        margin-right: auto;
    }
}

@media (max-width: 767.99px) {
    .our-services .card-body {
        padding: 14px !important;
    }
    .our-services .service-content .content-row {
        margin-bottom: 45.63px !important;
    }
    .swservice-row {
        flex-direction: column;
        .accordion-content {
            margin-bottom: 34px;
        }
    }
}
