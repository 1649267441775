.enhance-section {
    padding-top: 128px;
    .group-number {
        height: 254px;
    }
    &.hero-light {
        background-color: #f6faff !important;
        .group-number {
            background-color: #fff;
        }
    }
    &.hero-dark {
        background-color: #152e52;
        .group-number {
            background-color: #fff;
            p {
                color: #000;
            }
        }
    }
}

.enhance-title {
    text-transform: uppercase;
    text-align: center;
}

.enhance-content {
    padding: 2vw 18vw 6vw 18vw;
}

.group-number {
    padding: 58px;
    border-radius: 8px;
    margin-bottom: 36px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.number-icon {
    text-align: center;
    margin-bottom: 20px;
}
.number-icon:hover {
    img {
        transition: 1s;
        transform: rotate(360deg);
    }
}

.number-show {
    text-align: center;
}
