.apply-job {
    .modal-content {
        .modal-title {
            font-weight: 700;
            padding: 1.2rem !important;
        }
    }
    .upload-button {
        padding-top: 2em;
        button {
            background: #e1e3f5;
            border: #e1e3f5;
            margin-right: 2em;
        }
        input[type="file"] {
            display: none;
        }
    }
}
