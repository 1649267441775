.our-feature {
    min-height: 684px;
}
.feature-title {
    padding: 40px;
    text-align: center;
    text-transform: uppercase;
}

.feature-button {
    display: flex;
    justify-content: center;
    padding: 64px;
}
.group-feature {
    padding: 58px;
    margin-bottom: 36px;
    height: 420px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    &.hero-dark {
        background-color: #fff;
        .group-title {
            color: #071e40;
        }
    }
}

.group-title {
    &.group-title h2 {
        font-size: 26px;
        margin-bottom: 32px;
    }
    p {
        font-size: 16px;
    }
}

.group-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    margin-bottom: 20px;
}

.group-title {
    text-align: center;
}
