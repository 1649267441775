.hire-now {
    margin-top: 100px;
    h1 {
        font-weight: 700;
    }
    .notified {
        position: relative;
        padding: 10em 4em 10em 4em;
        margin-top: 6em;
        .notified-form {
            button {
                background-color: #071e40 !important;
                border-color: #071e40;
            }
        }
    }
    &.hero-dark {
        .notified {
            background-color: #152e52;
            h2,
            p {
                color: #fff;
            }
        }
    }
}

.filter-by {
    select {
        width: 10em;
    }
    label {
        padding: 6px;
    }
    .dropdown-divider {
        width: 100%;
    }
}
.career-description {
    margin-top: 34px;
    .sw-row {
        padding: 30px;
    }
    .career-card {
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        .card-footer {
            border: none;
            background-color: unset;
            padding: 2.75rem 1.25rem;
            button {
                background-color: #2836a0;
                border: #2836a0;
            }
        }
    }
    .accordion {
        svg {
            font-size: 2em;
        }
        .hero-light {
            background-color: #e1e3f5 !important;
            border-color: #e1e3f5 !important;
        }
        a {
            cursor: pointer;
        }
    }
}
