.hero-section {
    position: relative;
    margin-top: 44px;
    height: 70rem;
    .hero-image {
        position: absolute;
        right: 0;
        top: 0;
    }
    //  .hero-image {
    //     .hero-image1 {
    //         position: absolute;
    //         right: 0;
    //         top: 0;
    //     }
    //     .hero-image2 {
    //         position: absolute;
    //         right: 638px;
    //         top: 9%;
    //     }
    // }
}

.hero-info {
    padding-top: 70px;
    h1 {
        font-size: 2.9rem;
        font-weight: 700;
    }

    p {
        font-size: 1.5rem;
        padding: 15px 0;
    }
    button {
        svg {
            font-size: 1.35rem !important;
        }
    }

    .custom-button {
        padding: 14px 36px !important;
    }
}

.hero-dark {
    color: #fff;
}
.hero-light {
    color: #000 !important;
}

@media (max-width: 1300px) {
    .hero-image {
        max-width: 65% !important;
    }
}

@media (max-width: 991.99px) {
    .hero-section {
        height: 50rem;
        margin-top: 30px;
    }
    h1 {
        font-size: 2rem !important;
    }

    p {
        font-size: 1.2rem !important;
    }
    .hero-image {
        top: 25rem !important;
    }
}

