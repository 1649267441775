.root-dark {
    background-color: #071e40;
    .card {
        background-color: #152e52 !important;
        color: #fff;
    }
    .tags {
        .btn {
            color: #fff !important;
        }
    }
    .slider-content {
        .card {
            background-color: #4c4a68;
            color: #fff;
        }
    }
}

.root-light {
    background-color: #fff;
    .slider-content {
        .card {
            background: #f6faff;
            color: #000;
        }
    }
    .contact-content {
        .card {
            box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
    }
    .tags {
        .btn {
            color: #000 !important;
        }
    }
}

.custom-button {
    box-shadow: none !important;
    padding: 6px 36px !important;
    font-weight: 700 !important;
    text-decoration: none !important;
    border-radius: 6px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px !important;
    svg {
        font-size: 30px;
    }
}

.dark-btn {
    background-color: #ffc000 !important;
    border-color: #ffc000 !important;
    color: #000 !important;
    &.disabled {
        color: #000 !important;
        background-color: #846507 !important;
    }
}

.light-btn {
    background-color: #071e40 !important;
    border-color: #071e40 !important;
    color: #fff !important;
}

.custom-btn-lg {
    font-size: 25px !important;
}

.custom-btn-sm {
    font-size: 22px !important;
}

.custom-btn-sm-border-dark {
    background: transparent !important;
    border: 3px solid !important;
    color: #fff !important;
}

.custom-btn-sm-border-light {
    background: transparent !important;
    border: 3px solid !important;
    color: #174587 !important;
}

.section-title {
    // margin-bottom: 95.48px;
    h1 {
        font-size: 40px;
        font-weight: 700 !important;
    }
}

@media (min-width: 1920px) {
    .container {
        max-width: 1600px !important;
    }
}

@media (max-width: 767.99px) {
    .custom-btn-lg {
        font-size: 20px !important;
    }
    .custom-btn-sm {
        font-size: 12px !important;
    }
}

@media (max-width: 575.98px) {
    .custom-btn-sm {
        font-size: 11px !important;
    }
}
