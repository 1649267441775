header {
    padding: 30px;
}

.navbar-nav {
    position: relative;
    border-radius: 8px;

    .nav-link {
        font-size: 15px;
        font-weight: 500;
        // text-transform: uppercase;
        text-decoration: none;
        position: relative;
        z-index: 1;
        display: inline-block;
        text-align: center;

        &.active {
            color: #000 !important;
        }
    }
    .animation {
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 0;
        border-radius: 8px;
        transition: all 0.5s ease-out 0s;
    }

    .nav-link:nth-child(1) {
        width: 100px;

        &.active ~ .animation {
            width: 100px;
            left: 0;
        }
    }
    .start-home,
    .nav-link:nth-child(2) {
        width: 110px;
        &.active ~ .animation {
            width: 110px;
            left: 100px;
        }
    }

    .nav-link:nth-child(3) {
        width: 100px;
        &.active ~ .animation {
            width: 100px;
            left: 210px;
        }
    }

    .nav-link:nth-child(4) {
        width: 120px;
        &.active ~ .animation {
            width: 120px;
            left: 310px;
        }
    }

    .nav-link:nth-child(5) {
        width: 120px;
        &.active ~ .animation {
            width: 120px;
            left: 430px;
        }
    }

    .nav-link:nth-child(6) {
        width: 120px;
        &.active ~ .animation {
            width: 120px;
            left: 550px;
        }
    }
    .nav-link:nth-child(7) {
        width: 120px;
        &.active ~ .animation {
            width: 120px;
            left: 670px;
        }
    }
}
.navbar-brand {
    img {
        width: 256px;
    }
}

.navbar {
    button {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
}

.dark-class {
    .nav-link {
        color: #fff !important;
        .nav-link:hover {
            color: #fff;
        }
    }
    .nav-link.active {
        border-radius: 6px;
        color: #000 !important;
        background-color: #ffc000 !important;
    }
    .animation {
        background: #ffc000;
    }
}

.light-class {
    .nav-link {
        color: #000 !important;
        .nav-link:hover {
            color: #000;
        }
    }
    .nav-link.active {
        border-radius: 6px;
        color: #fafbfc !important;
        background-color: #071e40 !important;
    }
    .animation {
        background: #071e40;
    }
}

@media (max-width: 991.98px) {
    .navbar-nav {
        align-items: center;
        top: 30px;
    }
    .dark-class,
    .light-class {
        .animation {
            background: unset;
        }
    }
    .toggle-button {
        img {
            position: absolute;
            // left: 53px;
        }
    }
}

@media (max-width: 575.98px) {
    .navbar-brand {
        img {
            width: 144px;
        }
    }
}
