.working-section {
    padding: 80px 0;
    &.hero-light {
        background-color: #f6faff;
        .group-feature {
            background-color: #fff;
        }
    }
    &.hero-dark {
        background-color: #152e52;
        .group-feature {
            background-color: #fff;
            p {
                color: #000;
            }
        }
    }
}

.working-title {
    padding: 40px;
    text-transform: uppercase;
}

.work-content {
    padding-top: 2vw;
    .group-feature {
        border-radius: 12px;
        height: 425px;
        padding: 60px 30px;
        height: 360px;
        .group-image {
            margin-bottom: 22px;
        }
    }
}

@media (min-width: 1200px) {
    .working-section {
        margin-top: -346px;
    }
    .working-title {
        padding: 0;
    }
}
