.contact-section {
    padding-top: 50px;
    padding-bottom: 100px;
}
.contact-form {
    .input-group-text {
        background-color: #152e52;
    }
    svg {
        color: #fff;
    }
    input,
    textarea {
        background-color: inherit;
    }
}

.tags {
    button {
        margin-right: 4px;
    }
}

.slider-content {
    img {
        border-radius: 50% !important;
        width: 100px !important;
        height: 100px !important;
    }
    .card {
        // padding: 50px;
        border: none;
    }
}

.tags {
    button {
        margin-right: 18px;
        margin-bottom: 24px;
    }
}

.slick-dots li button:before {
    width: 20px !important;
    height: 5px !important;
    background: #fff;
    content: "" !important;
}

.text-area-message {
    // position: relative;
    button {
        position: absolute;
        top: 204px;
        right: 15px;
        &.hero-light {
            background-color: #071e40;
            color: #fff !important;
        }
        &.hero-dark {
            background-color: #ffc000;
        }
    }
}

.testimonial-section {
    margin-top: 10rem;
    .testimonial-content {
        &.hero-light {
            background-color: #f6faff;
            .slick-dots li button:before {
                background: #152e52 !important;
            }
        }
        &.hero-dark {
            background-color: #152e52;
        }
        .slick-dots {
            position: unset !important;
        }
    }
}

@media (max-width: 768px) {
    .slider-content {
        .card {
            padding: 12px !important;
        }
    }
    .contact-content {
        .card {
            .card-body {
                padding: unset !important;
                .row {
                    padding: 12px;
                }
            }
        }
    }
    .tag-content {
        margin-top: 3rem;
    }
}
