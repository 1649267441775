.footer-section {
    position: relative;
    padding-top: 10vh;
    background-color: #071e40;
    .footer-logo img {
        width: 100% !important;
    }
    .footer-image {
        position: absolute;
        bottom: 0;
    }
    .qr-image {
        width: 150px;
    }
    &.hero-light {
        background-color: #071e40;
    }

    .footer-link {
        ul {
            padding: 0;
            list-style-type: none;
            li a {
                color: #fff;
            }
        }
    }
    .goto-top {
        width: 100px;
        height: 100px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: -50px;
        left: 50%;
        button {
            position: absolute;
            top: 8%;
            left: 14%;
            font-size: 40px;
        }
        .btn:focus {
            box-shadow: none;
        }
    }
    .copyright {
        padding-bottom: 30px;
    }
}
.footer-content {
    position: relative;
    z-index: 99;
    margin-left: 3rem !important;
    margin-bottom: 7rem;
    .footer-logo {
        margin-bottom: 30px;
    }
    .social-links {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 30px;
    }
    .feedback-content {
        margin-bottom: 30px;
        p {
            font-size: 20px;
        }
    }
    p {
        color: #fff;
    }
    input {
        background-color: inherit;
    }
    .input-group-text {
        background-color: #ffc000;
    }
}

@media (max-width: 768px) {
    .footer-image {
        display: none !important;
    }
    .footer-content {
        margin: 0 !important;
    }
}

@media (max-width: 1000px) {
    // .footer-logo {
    //     margin-bottom: 15px !important;
    // }
    .social-links {
        margin-bottom: 10px !important;
    }

    .feedback-content {
        margin-bottom: 10px !important;
    }
}
