.about-section {
    padding-top: 44px;
    .about-content {
        .card {
            border: none;
        }
        .content-row {
            margin-bottom: 178.63px !important;
        }
        &.hero-light {
            .card {
                background-color: transparent !important;
                border: none;
            }
        }
    }
    &.hero-dark {
        background-color: #152e52;
    }
    &.hero-light {
        background-color: #f6faff;
    }
}
.aboutus-section {
    padding-bottom: 4rem;
}
.section-title {
    margin-bottom: 75px;
}

.why-sochware {
    ul {
        list-style: none;
        padding-left: 1em;
        li {
            display: flex;
            line-height: 3;
            img {
                width: 20px;
            }
            div {
                margin-right: 12px;
            }
        }
    }
}

.team-slider {
    .slider-content {
        ul {
            font-size: 25px;
            li {
                a {
                    margin: 0 7px;
                }
            }
        }
        .team-image {
            height: 200px !important;
            width: 200px !important;
        }
    }
    .slick-dots {
        position: unset !important;
    }
    &.hero-light {
        background-color: #f6faff;
        .text-muted {
            color: #071e40 !important;
        }
        .slick-dots li button:before {
            background: #2836a0 !important;
        }
        .slider-content {
            ul {
                li,
                a {
                    color: #071e40;
                }
            }
        }
    }
    &.hero-dark {
        .slider-content {
            ul {
                li,
                a {
                    color: #fff;
                }
            }
        }
    }
}
.partners {
    padding: 7em 0 7em 0;
}
.partner-logo {
    display: flex;
    width: 100%;
    padding-top: 80px;
    position: relative;
    flex-flow: row wrap;
    justify-content: space-between;
    .partner-circle {
        width: 100px;
        margin-right: 2em;
        margin-bottom: 2em;
        img {
            width: 100%;
            object-fit: contain;
            height: 100px;
            border: 5px solid;
            padding: 8px;
            justify-content: space-between;
        }
    }
}

@media (max-width: 991.99px) {
    .about-section .card-body {
        padding: 14px !important;
    }
    .about-section .about-content .content-row {
        margin-bottom: 45.63px !important;
    }
}
@media (max-width: 767.99px) {
    .about-section .card-body {
        padding: 14px !important;
    }
    .about-section .about-content .content-row {
        margin-bottom: 45.63px !important;
    }
}

@media only screen and (max-width: 768px) {
    .slider-content {
        .card-body {
            padding: 0.5em 1.2em;
        }
        .card-body .card-text {
            margin: 0;
        }
        .card img {
            width: 50%;
        }
    }
}
@media only screen and (max-width: 1200px) {
    .slider-content {
        .card img {
            width: 40%;
        }
    }
}
